import React, { useState, useEffect } from "react";
import Navbar from "../../../components/shared/Navbar/Navbar";
import Footer from "../../../components/shared/Footer/Footer";
import { useNavigate } from "react-router-dom";
import InstagramPost from "../../../components/Cards/InstagramPost";
import BottomNav from "../../../components/shared/BottomNav/BottomNav";

const AboutUs = () => {
    document.title = "About Us";
    const navigate = useNavigate();
    const [showSecondSection, setShowSecondSection] = useState(false);
    const [readMore, setReadMore] = useState(false)
    const [clampValue, setClampValue] = useState(`line-clamp-3`)

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowSecondSection(true);
        }, 500);

        return () => clearTimeout(timer);
    }, []);

    return (
        <div className="dark:bg-[#2c2c2c]">
            <Navbar />

            <div className="h-auto bg-[#FFF8E4]  flex flex-col justify-center mx-3 md:mx-10 rounded-xl">
                <div className={`px-3 transition-all delay-300 duration-[800ms] ease-in-out ${showSecondSection ? 'translate-y-[5%] md:translate-y-[10%]' : 'translate-y-[70%]'}`}>
                    <h1 className={`font-garamond drop-shadow-xl md:px-7 md:p-0 font-inter text-4xl md:text-4xl font-bold leading-tight text-center md:pt-14 text-wrap dark:text-black `}>
                        Know Where To with Muscat Where To
                    </h1>

                    <p className="my-6 mx-auto text-center max-w-[900px] leading-8 text-[#5E5D6D]">
                        <span className={`text-center text-lg md:text-xl mx-auto `} >
                            Welcome to Muscat Where To - your ultimate source for the latest events, offers, and deals in the city of Muscat, Oman. We are a dynamic team dedicated to curating and showcasing the best dining experiences, pool offers, hotel deals, and more in Muscat.
                            {
                                clampValue !== 'none' &&
                                (<span
                                    className="text-lg ml-1 cursor-pointer text-[#A48533] font-semibold inline"
                                    onClick={() => setClampValue('none')}
                                >
                                    Read More
                                </span>)}

                            {
                                clampValue === 'none' && (
                                    <span>
                                        <br></br>
                                        Our mission is to keep you informed and updated on the most exciting opportunities to dine, relax, and indulge in the city. Whether you're looking for a special dining promotion, a relaxing pool day, or a fantastic hotel package, Muscat Where To is your go-to resource.
                                        <br></br>
                                        Join us as we bring you the hottest deals, the trendiest events, and the most exclusive offers that Muscat has to offer. Let us be your guide to all things culinary, leisure, social, adventure, and hospitality in Muscat.
                                    </span>
                                )
                            }

                        </span>

                    </p>

                    <div className="w-full mt-4 flex justify-center items-center space-x-2">
                        <button onClick={() => navigate('/login')} className="glow-button bg-[#C0A04C] text-white w-1/2 md:w-44 h-11 px-4 py-2 rounded-lg hover:shadow-lg">
                            Log in
                        </button>
                        <button onClick={() => navigate('/contact')} className="bg-[#FFF4D6] text-[#C0A04C] w-1/2 md:w-44 h-11 px-4 py-2 gap-1.5 rounded-lg">
                            Contact us
                        </button>
                    </div>
                </div>

                <img
                    className={`transition-all delay-300 duration-[800ms] ease-in-out ${showSecondSection ? 'translate-y-[50%] md:translate-y-[50%] lg:translate-y-[40%] opacity-1' : 'translate-y-[70%] opacity-0'} border border-8 border-white mx-auto w-[90%] md:w-7/12 max-w-7/12 aspect-video rounded-2xl`}
                    src="https://images.unsplash.com/photo-1621680696874-edd80ce57b72?q=80&w=1591&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    alt=""
                />
            </div>


            <section className={`h-full mt-28 md:mt-36 lg:mt-52`}>
                <InstagramPost />
            </section>

            <Footer />

            <div>
                <BottomNav />
            </div>
        </div >
    );
};

export default AboutUs;