import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { addToFavorites } from '../../http/index';
import toast, { Toaster } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { enqueueApiRequest } from '../../utils/Apiqueue';

const UpcomingEventsCard = ({ event, showNumberBox, setNumber }) => {
    const [isLiked, setIsLiked] = useState(false);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const { user, isAuth } = useSelector((state) => state.auth);
    const navigate = useNavigate();

    useEffect(() => {
        // Check if the user is logged in and the event.likes array includes the user's ID
        let liked = isAuth && event.likes.includes(user._id);

        // Check if the card is in the local storage favorites
        const favorites = JSON.parse(localStorage.getItem('favorites')) || [];
        if (favorites.includes(event._id)) {
            liked = true;
        }

        setIsLiked(liked);
    }, [event.likes, user, isAuth, event._id]);

    const [ticketSale, setTicketSale] = useState(false)
    
    useEffect(() => {
        setTicketSale(event.categories.some((category) => category.className !== null));

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleResize = () => {
        const width = window.innerWidth;
        setScreenWidth(width);
        setIsMobile(width <= 768);
    };

    const favoriteFeature = async (eventid) => {
        setIsLiked(!isLiked);
        setIsLiked(!isLiked)
        const requestFn = async () => {
            const eventdata = { eventid: eventid };
            const { data } = await addToFavorites(eventdata);
            toast.success(data.message);
        };

        const saveToLocalStorage = (eventid) => {
            try {
                // Retrieve current favorites from local storage
                let favorites = JSON.parse(localStorage.getItem('favorites')) || [];

                // Check if eventid is already in favorites
                if (!favorites.includes(eventid)) {
                    favorites.push(eventid);
                    localStorage.setItem('favorites', JSON.stringify(favorites));
                    console.log('Item added to local storage.');
                } else {
                    console.log('Item already in local storage.');
                }
            } catch (error) {
                console.error('Error saving to local storage:', error);
            }
        };

        try {
            if (!window.isNative) {
                await requestFn();
            }
            else if (window.isNative && user != null && isAuth) {
                await requestFn();
            } else if (window.isNative && user == null && isAuth == false) {
                saveToLocalStorage(eventid)
            }

        } catch (error) {
            console.log(error)
            if (error.response.status == 401) {
                // toast.error("session expired Login again")
                const currentPath = window.location.pathname;
                enqueueApiRequest(requestFn);
                navigate('/login', { state: { from: currentPath } });
            }
        }
    };

    const handleCalling = () => {
      const phoneNumber = String(event.phoneNo); // Ensure the phone number is a string

      if (window.isNative) {
        // Define the action and payload
        const action = 'call'; // The action to perform
        const payload = { phoneNumber }; // Create an object with the phone number

        // Construct the message object
        const message = JSON.stringify({
          action,
          ...payload // Spread the payload object into the message
        });

        // Send the message to the React Native WebView
        if (window.ReactNativeWebView) {
          window.ReactNativeWebView.postMessage(message);
        }
      } else {
        if (isMobile) {
          const tempLink = document.createElement("a");
          tempLink.href = `tel:+${phoneNumber}`; // Create a tel: link for mobile devices
          tempLink.click(); // Trigger the call by clicking the link
        } else {
          showNumberBox(true); // Show a number input box or any other UI element
          setNumber(phoneNumber); // Set the number in your state or UI
        }
      }
    };


    const handleBooking = (e) => {
        e.stopPropagation(); // Prevent event propagation to parent elements

        if (ticketSale) {
            navigate(`/bookticket/${event._id}`);
            return;
        } else {
            handleCalling();
        }
    };

    return (
        <div className='dark:text-white mt-2 '>
            <div>
                <div onClick={() => navigate(`/events/${event._id}`)} className='cursor-pointer relative rounded-2xl mx-2 mb-2 bg-[#F3F3F3] dark:bg-[#454545] top-0 md:mt-5'>
                    <div className='top-0 rounded-2xl'>
                        <img className='rounded-2xl object-cover aspect-square' src={event.displayPhoto} alt='' />
                        <button onClick={(e) => { e.stopPropagation(); favoriteFeature(event._id); }} className='absolute top-2 right-2 bg-white text-black rounded-full z-20 p-2'>
                            {isLiked ? <img className='' src='/images/icons/heart-fav.svg' alt='' /> : <img src='/images/icons/heart.svg' alt='' />}
                        </button>
                        <div className='flex flex-col p-2'>
                            <p className='text-base  mt-2 font-medium truncate'>{event.title},</p>
                            <p className='text-base  mt-2 font-medium truncate'>{event.location?.name || '\u00A0'}</p>
                            <p className='mt-1 mb-1 text-sm font-light truncate'> {[...new Set(event.eventCategory.map(subcategory => subcategory.name))].join(', ')}</p>
                            <div className='flex items-center justify-between md:space-x-2'>

                                <button onClick={(e) => { e.stopPropagation(); handleBooking(e) }} type='button' className='text-white hover:bg-[#A48533] bg-[#C0A04C] focus:ring-0 focus:outline-none focus:ring-0 font-medium rounded-lg text-sm px-4 py-2 text-center mr-3 md:mr-0 dark:bg-[#C0A04C] dark:hover:bg-[#A48533] w-full'>
                                    {
                                        ticketSale ?
                                            <>
                                                Book Now
                                            </>
                                            :
                                            <>
                                                Call Now
                                            </>
                                    }
                                </button>

                                {/* <Link to='/contact' className='w-full'> */}
                                    <button type='button' className='hidden md:block text-white bg-[#C0A04C] hover:bg-[#A48533] hover:text-white focus:ring-0 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 text-center mr-3 md:mr-0 dark:bg-[#C0A04C] dark:hover:bg-[#A48533] dark:focus:ring-blue-0 w-full'>
                                        Contact Us
                                    </button>
                                {/* </Link> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default UpcomingEventsCard;
