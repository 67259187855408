import React, { useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { vendorLogout } from '../../../http/index'
import { setAuth } from '../../../store/authSlice'
import toast, { Toaster } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux'
import EditPopupMessage from '../../EditEventModal/EditPopupMessage'

const AdminNavbar = () => {

    const [unread, setUnread] = useState(false)
    const navigate = useNavigate()
    
    const funVendorLogout = async () => {
        try {
            const { data } = await vendorLogout()
            dispatch(setAuth(data));
            toast.success("logged out")
            navigate("/vendor/login");
        } catch (error) {
            window.alert(error)
            console.log(error)
        }
    }


    const dispatch = useDispatch();
    const { isAuth, user } = useSelector((state) => state.auth)

    return (
        <>
            <div className="navbar flex justify-between space-x-8">
                <div className="left">
                    <Link to="/admin/home" className="flex md:flex items-center">
                        {/* <img src="/images/logo/logo-main.png" className="dark:hidden flex h-10 md:mr-3" alt="MWT Logo" /> */}
                        {/* <img src="/images/logo/logo-main-light.png" className="hidden dark:flex h-10 md:mr-3" alt="MWT Logo" /> */}
                        <img src="/images/logo/logo.png" className="flex h-6 mr-3" alt="MWT Logo" />
                        {/* <img src="/images/icons/logo-light.svg" className="dark:flex hidden flex h-6 mr-3" alt="MWT Logo" /> */}
                    </Link>
                </div>
                <div className='flex align-middle items-center space-x-3'>
                    <a href='https://analytics.google.com/' target="_blank" rel="noopener noreferrer" className='hover:bg-slate-100 rounded cursor-pointer p-2'>
                        <svg className='h-6 w-6' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path fill="none" d="M0 0h24v24H0z"></path><path d="M5 3V19H21V21H3V3H5ZM19.9393 5.93934L22.0607 8.06066L16 14.1213L13 11.121L9.06066 15.0607L6.93934 12.9393L13 6.87868L16 9.879L19.9393 5.93934Z"></path></svg>
                    </a>
                    {
                        unread
                            ?
                            <button className='hover:bg-slate-100 rounded cursor-pointer p-2'>
                                <img onClick={(() => navigate('/admin/notifications'))} className='h-5 w-5' src="/images/icons/notification.svg" alt="" />
                            </button>
                            :
                            <button className='hover:bg-slate-100 rounded cursor-pointer p-2'>
                                <img onClick={(() => navigate('/admin/notifications'))} className='h-5 w-5' src="/images/icons/notification-1.svg" alt="" />
                            </button>
                    }
                    <a className='hover:bg-slate-100 rounded cursor-pointer p-2' href='https://dashboard.tawk.to/?lang=en#/dashboard' target="_blank" rel="noopener noreferrer">
                        <img className='h-5 w-5' src="/images/icons/chat.png" alt="" />
                    </a>
                    <button >
                        <img onClick={() => funVendorLogout()} className='h-5 w-5' src="/images/icons/logout.png" alt="" />
                    </button>
                </div>
            </div>
        </>
    )
}

export default AdminNavbar