import React from 'react'
import { Link } from 'react-router-dom'

const Sidebar = () => {
    return (
        <div className="flex bg-gray-100 text-gray-900 fixed">
            <aside className="flex h-screen w-20 flex-col items-center border-r border-gray-200 bg-white">
                <Link to='/admin/home'>
                    <div className="flex h-[5rem] w-full items-center justify-center border-b border-gray-200 p-2">
                        <img src="/images/logo/logo-main.png" className=" flex h-10 md:mr-3" alt="MWT Logo" />
                    </div>
                </Link>
                <nav className="flex flex-1 flex-col gap-y-4 pt-3">
                    <Link to='/admin/home' className='text-gary-400 group relative rounded-xl p-2 hover:bg-gray-50'>
                        <img src="/images/icons/pwa-home.svg" className={`${window.location.pathname.includes('home') ? 'bg-gray-100 p-2 rounded-md' : 'p-2'}`} alt="" />
                        <div className="absolute inset-y-0 left-12 hidden items-center group-hover:flex">
                            <div className="relative whitespace-nowrap rounded-md bg-white px-4 py-2 text-sm font-semibold text-gray-900 drop-shadow-lg">
                                <div className="z-50 absolute inset-0 -left-1 flex items-center">
                                    <div className="h-2 w-2 rotate-45 bg-white"></div>
                                </div>
                                Home
                            </div>
                        </div>
                    </Link>
                    <Link to='/admin/users' className='text-gary-400 group relative rounded-xl p-2 hover:bg-gray-50'>
                        <img src="/images/icons/users.png" className={`h-10 w-10 ${window.location.pathname.includes('users') ? 'bg-gray-100 p-2 rounded-md' : 'p-2'}`} alt="" />
                        <div className="absolute inset-y-0 left-12 hidden items-center group-hover:flex">
                            <div className="relative whitespace-nowrap rounded-md bg-white px-4 py-2 text-sm font-semibold text-gray-900 drop-shadow-lg">
                                <div className="z-50 absolute inset-0 -left-1 flex items-center">
                                    <div className="h-2 w-2 rotate-45 bg-white"></div>
                                </div>
                                Users
                            </div>
                        </div>
                    </Link>
                    <Link to='/admin/vendors' className='text-gary-400 group relative rounded-xl p-2 hover:bg-gray-50'>
                        <img src="/images/icons/vendor.png" className={`h-10 w-10 ${window.location.pathname.includes('vendors') ? 'bg-gray-100 p-2 rounded-md' : 'p-2'}`} alt="" />
                        <div className="absolute inset-y-0 left-12 hidden items-center group-hover:flex">
                            <div className="relative whitespace-nowrap rounded-md bg-white px-4 py-2 text-sm font-semibold text-gray-900 drop-shadow-lg">
                                <div className="z-50 absolute inset-0 -left-1 flex items-center">
                                    <div className="h-2 w-2 rotate-45 bg-white"></div>
                                </div>
                                Vendors
                            </div>
                        </div>
                    </Link>
                    <Link to='/admin/events' className='text-gary-400 group relative rounded-xl p-2 hover:bg-gray-50'>
                        <img src="/images/icons/events.png" className={`h-10 w-10 ${window.location.pathname.includes('events') ? 'bg-gray-100 p-2 rounded-md' : 'p-2'}`} alt="" />
                        <div className="absolute inset-y-0 left-12 hidden items-center group-hover:flex">
                            <div className="relative whitespace-nowrap rounded-md bg-white px-4 py-2 text-sm font-semibold text-gray-900 drop-shadow-lg">
                                <div className="z-50 absolute inset-0 -left-1 flex items-center">
                                    <div className="h-2 w-2 rotate-45 bg-white"></div>
                                </div>
                                Events / Offers
                            </div>
                        </div>
                    </Link>
                    {/* <Link to='/admin/offers' className='text-gary-400 group relative rounded-xl p-2 hover:bg-gray-50'>
                        <img src="/images/icons/offers.png" className={`h-10 w-10 ${window.location.pathname.includes('offers') ? 'bg-gray-100 p-2 rounded-md' : 'p-2'}`} alt="" />
                        <div className="absolute inset-y-0 left-12 hidden items-center group-hover:flex">
                            <div className="relative whitespace-nowrap rounded-md bg-white px-4 py-2 text-sm font-semibold text-gray-900 drop-shadow-lg">
                                <div className="z-50 absolute inset-0 -left-1 flex items-center">
                                    <div className="h-2 w-2 rotate-45 bg-white"></div>
                                </div>
                                Vouchers
                            </div>
                        </div>
                    </Link> */}
                    <Link to='/admin/categories' className='text-gary-400 group relative rounded-xl p-2 hover:bg-gray-50'>
                        <img src="/images/icons/categories.png" className={`h-10 w-10 ${window.location.pathname.includes('categories') ? 'bg-gray-100 p-2 rounded-md' : 'p-2'}`} alt="" />
                        <div className="absolute inset-y-0 left-12 hidden items-center group-hover:flex">
                            <div className="relative whitespace-nowrap rounded-md bg-white px-4 py-2 text-sm font-semibold text-gray-900 drop-shadow-lg">
                                <div className="z-50 absolute inset-0 -left-1 flex items-center">
                                    <div className="h-2 w-2 rotate-45 bg-white"></div>
                                </div>
                                Categories
                            </div>
                        </div>
                    </Link>
                    <Link to='/admin/venue' className='text-gary-400 group relative rounded-xl p-2 hover:bg-gray-50'>
                        <img src="/images/icons/location.png" className={`h-10 w-10 ${window.location.pathname.includes('venue') ? 'bg-gray-100 p-2 rounded-md' : 'p-2'}`} alt="" />
                        <div className="absolute inset-y-0 left-12 hidden items-center group-hover:flex">
                            <div className="relative whitespace-nowrap rounded-md bg-white px-4 py-2 text-sm font-semibold text-gray-900 drop-shadow-lg">
                                <div className="z-50 absolute inset-0 -left-1 flex items-center">
                                    <div className="h-2 w-2 rotate-45 bg-white"></div>
                                </div>
                                Venue
                            </div>
                        </div>
                    </Link>
                </nav>

                <div className="flex flex-col items-center gap-y-4 py-10">
                </div>
            </aside>
        </div>
    )
}

export default Sidebar