
import React from 'react'

const SkeletonCard = () => {
    return (
        <div>
            <div role="status" className="mx-3 pb-3 max-w-sm pt-0 border border-gray-200 rounded shadow animate-pulse dark:border-gray-700">
                <div className="flex items-center justify-center h-48 mb-4 bg-gray-300 rounded dark:bg-gray-700">
                    <img src="/images/logo/logo-main.png" className="h-16" />
                </div>
                <div className="mx-2 h-2.5 bg-gray-200 rounded-full dark:bg-gray-700  mb-4"></div>
                <div className="mx-2 h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                <div className="mx-2 h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                <div className="mx-2 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                <div className="flex items-center mt-4 space-x-3">
                    <div>
                        <div className="mx-3 w-40 h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 mb-2"></div>
                        <div className="mx-3 w-40 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default SkeletonCard