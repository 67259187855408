import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'

const PwaCards = ({ data, setNumber }) => {

    const navigate = useNavigate();
    const [ticketSale, setTicketSale] = useState(false)
    const isMobile = window.matchMedia('(display-mode: standalone)').matches;

    const handleCalling = (phone) => {
        const phoneNumber = String(phone); // Ensure the phone number is a string

          if (window.isNative) {
            // Define the action and payload
            const action = 'call'; // The action to perform
            const payload = { phoneNumber }; // Create an object with the phone number

            // Construct the message object
            const message = JSON.stringify({
              action,
              ...payload // Spread the payload object into the message
            });

            // Send the message to the React Native WebView
            if (window.ReactNativeWebView) {
              window.ReactNativeWebView.postMessage(message);
            }
          } else {
            if (isMobile) {
                const tempLink = document.createElement("a");
                tempLink.href = `tel:+${phone}`; // Replace with your actual phone number
                tempLink.click();
            } else {
                setNumber(data.phoneNo)
            }
        }
    };

    useEffect(() => {
        setTicketSale(data.categories.some((category) => category.className !== null));
    }, []);

    const handleBooking = (e) => {
        e.stopPropagation(); // Prevent event propagation to parent elements

        if (ticketSale) {
            navigate(`/bookticket/${data._id}`);
            return;
        } else {
            handleCalling(data.phoneNo);
        }
    };
    return (
        <div onClick={() => navigate(`/events/${data._id}`)} className="mb-1 flex w-11/12 border border-2 dark:border-0 rounded-md bg-white dark:bg-[#454545] hover:bg-[#C0A04C] hover:text-white cursor-pointer drop-shadow-2xl">
            <div className="image h-28 w-28">
                <img className='ml-0 object-cover aspect-square p-1.5 h-full w-full rounded-xl' src={`${data.displayPhoto}`} alt="" />
            </div>
            <div className="content w-4/6 mt-2">
                <div>
                    <div className="text-xs font-medium truncate overflow-hidden">{data.title}</div>
                    <p className='text-xs font-medium truncate overflow-hidden'>{data.location?.name || ""}</p>
                </div>
                <div className='py-4'></div>
                <div>
                    <button onClick={(e) => { e.stopPropagation(); handleBooking(e) }} className='text-white hover:bg-[#A48533] bg-[#C0A04C] focus:ring-0 focus:outline-none focus:ring-0 font-medium rounded text-xs py-1 px-1 mt-1'>
                        {
                            ticketSale ?
                                <>
                                    Book Now
                                </>
                                :
                                <>
                                    Call Now
                                </>
                        }
                    </button>
                </div>
            </div>
        </div>
    )
}

export default PwaCards

// <div onClick={() => navigate(`/events/${data._id}`)} className="my-3 flex w-80 md:w-72 border border-2 dark:border-0 rounded-md space-x-2 bg-white dark:bg-[#454545] hover:bg-[#C0A04C] hover:text-white cursor-pointer drop-shadow-2xl">
//     <div className="h-32 w-32"> {/* Fixed width for the image container */}
//         <img className='ml-0 object-cover aspect-square p-1.5 h-full w-full rounded-xl' src={`${data.displayPhoto}`} alt="" /> {/* Image takes full container height and width */}
//     </div>
//     <div className="flex-col justify-between text-left mt-3 flex-1"> {/* Use flex-1 to take up remaining space */}
//         <div>
//             <div className="text-xs font-medium truncate overflow-hidden">"{data.title}asdfasdfasdfasdfasdfasdfasfdasdfasdfasdfasdfafds"</div> {/* Truncate title */}
//             <p className='text-xs font-medium truncate overflow-hidden'>{data.location?.name || ""}</p> {/* Truncate location */}
//         </div>
//         <div className='py-3'></div>
//         <div>
//             <button onClick={(e) => { e.stopPropagation(); handleBooking(e) }} className='text-white hover:bg-[#A48533] bg-[#C0A04C] focus:ring-0 focus:outline-none focus:ring-0 font-medium rounded text-xs py-1 px-1 mt-1'>
//                 {
//                     ticketSale ?
//                         <>
//                             Book Now
//                         </>
//                         :
//                         <>
//                             Call Now
//                         </>
//                 }
//             </button>
//         </div>
//     </div>
// </div>