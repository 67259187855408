import React from 'react'
import { redirect, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useSelector } from 'react-redux'

const FavoriteRoute = (props) => {

    const { Component } = props;

    const navigate = useNavigate();

    const { user, isAuth } = useSelector((state) => state.auth);

    useEffect(() => {
        function checkLogin() {
            if (!window.isNative && (!isAuth || user == null || user.type !== 'user')) {
                redirect('/login')
            }
        }
        checkLogin();
    }, [navigate]);


    return (
        <Component />
    )
}

export default FavoriteRoute