import React, { useState, useEffect } from 'react'
import Navbar from '../../../components/shared/Navbar/Navbar'
import Tabbar from '../../../components/shared/Tabbar/Tabbar'
import Footer from '../../../components/shared/Footer/Footer'
import BottomNav from '../../../components/shared/BottomNav/BottomNav'
import { WhereToEvents, GetAllCategory } from '../../../http/index';
import { Link } from 'react-router-dom'

const Categories = () => {
    
    const [response, setResponse] = useState({})

    let coordinates = [];

    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const fetchCategories = async () => {
            setLoading(true);
            try {
                const res = await GetAllCategory();
                setCategories(res.data.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching categories:', error);
                setLoading(false);
            }
        };
        const fetchdata = async () => {
            setLoading(true)

            try {
                const { data } = await WhereToEvents()
                console.log(data.data)
                setResponse(data)

                setLoading(false)
            } catch (error) {
                // console.log(error)
                setLoading(false)
            }
        }

        fetchdata()

        fetchCategories();

    }, []);


    if (response.data != null) {
        response.data.map((event, index) => {
            if (event && event.location.coordinates && event.location.coordinates != null) {
                coordinates.push(event.location.coordinates)
            }
        })
    }

    return (
        <div className='relative h-screen dark:bg-[#2c2c2c] dark:text-white contactmargine '>
            <div className='z-20 '>
                <Navbar />
                <div className="hidden md:block">
                    <Tabbar />
                </div>
            </div>

            <section className='dark:bg-[#2c2c2c] dark:text-white pb-20'>
                <section className="screenWrapper flex justify-center items-center align-middle mt-5">
                    <section className='w-full md:w-full sm:mx-5 md:mx-5 md:w-10/12 xl:w-9/12 2xl:w-7/12'>
                        <div className="hidden md:flex justify-center mt-3  ">
                            <span className='capitalize text-2xl font-bold'>
                                Categories
                            </span>
                        </div>



                        <div className='min-h-screen  mainContainer grid grid-cols-1 '>
                            <div className="1 h-11/12 col-span-2 overflow-x-auto">
                                <div className='mx-5 grid grid-cols-2 md:grid-cols-4 gap-3 p-3'>
                                    {
                                        !loading && categories && categories.map(category => (
                                            <>
                                                <div className='h-40 md:h-60 grid md:grid-cols-1 grid-rows-2 gap-3'>
                                                    <Link to={`/category/${category.categoryURL}`}>
                                                        <div className='relative'>
                                                            <img className='rounded-md h-40 md:h-60 w-full bg-gray-400 bg-blend-multiply hover:bg-grey-500 grayscale-10' src={category.photo} alt='' />
                                                            <div className="rounded absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-black opacity-50 group-hover:opacity-0 rounded-lg"></div>
                                                            <span className='absolute bottom-0 left-0 text-white p-2 font-bold'>{category.name}</span>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </>
                                        ))
                                    }

                                </div>
                            </div>
                        </div>

                    </section>
                </section>
            </section>

            <div className='dark:bg-[#2c2c2c] dark:text-white'>
                < Footer />
            </div>

            <div>
                <BottomNav />
            </div>
        </div>
    )
}

export default Categories